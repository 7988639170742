import { IEnvironment } from '@shared/core/interfaces/environment.interface';

export const environment: IEnvironment = {
  production: true,
  envName: 'prod',
  baseUrl: 'https://api.pickwings.ch',
  firebase: {
    apiKey: 'AIzaSyDE3GoG4Tczcj2_yhsZgUhhDOmvIeEZJWw',
    authDomain: 'pickwings-webapps.firebaseapp.com',
    projectId: 'pickwings-webapps',
    storageBucket: 'pickwings-webapps.appspot.com',
    messagingSenderId: '108292520554',
    appId: '1:108292520554:web:4f243434eefc5694be2813',
    measurementId: 'G-55N7Q8EZVR',
  },
  isBuildInfoShown: false,
  defaultLanguage: 'de',
  languages: ['en', 'de', 'fr', 'it'],
  panelType: 'consignee',
  relateWebsite: {
    shipper: 'https://shipper.pickwings.ch/',
    carrier: 'https://carrier.pickwings.ch/',
    about: 'https://pickwings.ch/',
  },
};
